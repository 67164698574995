import { createApp } from 'vue';
import App from './App.vue';
import router from '/router'; // 导入Vue Router
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import 'vant/lib/index.css';
import TDesign from 'tdesign-mobile-vue';
import 'tdesign-mobile-vue/es/style/index.css';
import { Overlay, Lazyload, ImagePreview, Notify, Loading, Swipe, SwipeItem, Tab, Tabs, Popup, Card, Tag, Button, Form, Field, Cell, CellGroup, Radio, RadioGroup, Empty, Icon, Cascader, NoticeBar, Switch, TimePicker, Toast, Dialog } from "vant";


const app = createApp(App);

app.use(Tab);
app.use(Tabs);
app.use(Popup);
app.use(Card);
app.use(Tag);
app.use(Button);

app.use(Form);
app.use(Field);
app.use(Cell);
app.use(CellGroup);
app.use(Radio);
app.use(RadioGroup);
app.use(Icon);
app.use(Empty);
app.use(Cascader);
app.use(NoticeBar);
app.use(Switch);
app.use(TimePicker);
app.use(Toast);
app.use(Dialog);
app.use(Swipe);
app.use(SwipeItem);
app.use(Loading);
app.use(Notify);
app.use(Lazyload);
app.use(ImagePreview);
app.use(Overlay);

app.use(router);
app.use(ElementPlus, {
    locale: zhCn,
})

app.use(TDesign);

// const debounce = (fn, delay) => {
//     let timer
//     return (...args) => {
//         if (timer) {
//             clearTimeout(timer)
//         }
//         timer = setTimeout(() => {
//             fn(...args)
//         }, delay)
//     }
// }

// const _ResizeObserver = window.ResizeObserver;
// window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
//     constructor (callback) {
//         callback = debounce(callback, 200);
//         super(callback);
//     }
// }

document.addEventListener('DOMContentLoaded', () => {
    // 创建 script 标签并设置 src 属性
    const script = document.createElement('script');
    script.src = `https://map.qq.com/api/gljs?v=1.exp&key=${process.env.VUE_APP_MAP_KEY}&libraries=service`;
    // 将 script 标签添加到 head 中
    document.head.appendChild(script);
});


for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})


function detectDevice () {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // 返回一个对象，包含是否是 iOS 或 Android 的布尔值
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
    const isAndroid = /android/i.test(userAgent)
    if (isIOS) {
        localStorage.setItem('system', 'iOS');
    } else if (isAndroid) {
        // 执行 Android 设备的初始化代码
        localStorage.setItem('system', 'Android');
    } else {
        // 其他设备
        localStorage.setItem('system', 'Other');
    }

}

detectDevice();

app.mount('#app');