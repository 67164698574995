import { ElMessage } from 'element-plus';
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

// 登陆状态检测
export function loginStatusCheck (storeId) {
    const session = localStorage.getItem("session");
    const router = useRouter(); // 确保这个函数在组件的setup()内调用
    var check = true

    const data = {
        session: session,
        storeId: storeId,
    };

    axios
        .post("https://www.minoai.com/api/front/check_login_status/", data)
        .then((res) => {
            if (res.status === 200) {
                check = true
                localStorage.setItem('loginStatus', true);
            } else if (res.status === 208) {
                localStorage.removeItem('session'); // 清除特定的session
                localStorage.removeItem('loginStatus');
                ElMessage.error("登录超时");
                router.push('/guide');
                check = false
            } else {
                check = false
            }
        })
        .catch((error) => {
            localStorage.removeItem('session'); // 清除特定的session
            localStorage.removeItem('loginStatus');
            // console.error("请求失败：", error);
            // ElMessage.error("请求失败，请稍后重试");
        });
    return check
}

// 管理员登陆状态检测
export async function MloginStatusCheck () {
    return new Promise((resolve) => {
        const session = localStorage.getItem("m_session");
        const code = localStorage.getItem('m_code')
        const router = useRouter(); // 确保这个函数在组件的setup()内调用

        const data = {
            session: session,
            storeId: code,
        };

        axios
            .post("https://www.minoai.com/api/front/check_login_status/", data)
            .then((res) => {
                if (res.status === 200) {
                    resolve(true)
                } else {
                    localStorage.removeItem('m_session'); // 清除特定的session
                    localStorage.removeItem('m_code'); // 清除特定的session

                    // router.push('/manage/managerlogin');
                    resolve(false)
                }
            })
            .catch((error) => {
                localStorage.removeItem('m_session'); // 清除特定的session
                localStorage.removeItem('m_code'); // 清除特定的session
                resolve(false)
            });
    })
}

// 客服登陆状态检测
export function loginCheck_service (service_id) {
    const session = localStorage.getItem("service_session");
    const router = useRouter(); // 确保这个函数在组件的setup()内调用
    var check = true

    const data = {
        session: session,
        service_id: service_id,
    };

    axios
        .post("https://www.minoai.com/api/front/check_login_service/", data)
        .then((res) => {
            if (res.status === 200) {
                check = true
                localStorage.setItem('serrviceLogin', true);
            } else if (res.status === 208) {
                localStorage.removeItem('service_session'); // 清除特定的session
                localStorage.removeItem('serrviceLogin');
                localStorage.removeItem("service_id");
                ElMessage.error("登录超时");
                router.push('/h5/service_login');
                check = false
            } else {
                check = false
            }
        })
        .catch((error) => {
            localStorage.removeItem('service_session'); // 清除特定的session
            localStorage.removeItem('serrviceLogin');
            localStorage.removeItem("service_id");
            // console.error("请求失败：", error);
            // ElMessage.error("请求失败，请稍后重试");
        });
    return check
}

// 获取系统数据
export async function getSystemData (dataId) {
    const router = useRouter(); // 确保这个函数在组件的setup()内调用
    const resdata = ref(null); // 初始化为null或任何合适的初始值

    const data = {
        token: 11408,
        dataId: dataId
    };

    try {
        const response = await axios.post("https://www.minoai.com/api/front/get_system_data/", data);
        if (response.status === 200) {
            resdata.value = response.data;
        } else if (response.status === 208) {
            localStorage.removeItem('session'); // 清除特定的session
            ElMessage.error("登录超时");
            router.push('/guide');
        }
    } catch (error) {
        console.error("请求失败：", error);
        ElMessage.error("请求失败，请稍后重试");
    }
    return resdata.value; // 注意：这里返回的是一个ref，需要在组件中适当处理
}

// 客服获取系统数据
export async function getSystemDataSV (dataId) {
    const resdata = ref(null); // 初始化为null或任何合适的初始值

    const data = {
        token: 11408,
        dataId: dataId
    };

    try {
        const response = await axios.post("https://www.minoai.com/api/front/get_system_data/", data);
        if (response.status === 200) {
            resdata.value = response.data;
        } else if (response.status === 208) {
            ElMessage.error("登录超时");
        }
    } catch (error) {
        console.error("请求失败：", error);
        ElMessage.error("请求失败，请稍后重试");
    }
    return resdata.value; // 注意：这里返回的是一个ref，需要在组件中适当处理
}


export function RandomNumber () {
    const now = new Date();
    const randomPart = Math.floor(Math.random() * 100000); // 生成5位数的随机数部分
    const formattedNumber = randomPart.toString().padStart(5, '0'); // 确保是5位数字
    const timestamp = now.getTime(); // 获取当前时间戳
    const uniqueNumber = formattedNumber + timestamp; // 结合随机数和时间戳以确保唯一性
    const randomNum = uniqueNumber.slice(0, 5); // 取前5位作为结果
    return randomNum
}

export function generateTimestamp () {
    const currentTime = ref(new Date());
    const hours = currentTime.value.getHours().toString().padStart(2, '0');
    const minutes = currentTime.value.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
}

export function generateUuid () {
    return uuidv4();
}

//gcj02 to wgs84 
export const gcj02towgs84 = (lng, lat) => {
    const a = 6378245.0;
    const ee = 0.00669342162296594323;
    const pi = Math.PI;

    function transformlat (lng, lat) {
        let ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(
            lng));
        ret += (20.0 * Math.sin(6.0 * lng * pi) + 20.0 * Math.sin(2.0 * lng * pi)) * 2.0 / 3.0;
        ret += (20.0 * Math.sin(lat * pi) + 40.0 * Math.sin(lat / 3.0 * pi)) * 2.0 / 3.0;
        ret += (160.0 * Math.sin(lat / 12.0 * pi) + 320 * Math.sin(lat * pi / 30.0)) * 2.0 / 3.0;
        return ret;
    }

    function transformlng (lng, lat) {
        let ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng));
        ret += (20.0 * Math.sin(6.0 * lng * pi) + 20.0 * Math.sin(2.0 * lng * pi)) * 2.0 / 3.0;
        ret += (20.0 * Math.sin(lng * pi) + 40.0 * Math.sin(lng / 3.0 * pi)) * 2.0 / 3.0;
        ret += (150.0 * Math.sin(lng / 12.0 * pi) + 300.0 * Math.sin(lng / 30.0 * pi)) * 2.0 / 3.0;
        return ret;
    }

    let dlat = transformlat(lng - 105.0, lat - 35.0);
    let dlng = transformlng(lng - 105.0, lat - 35.0);
    const radlat = lat / 180.0 * pi;
    let magic = Math.sin(radlat);
    magic = 1 - ee * magic * magic;
    const sqrtmagic = Math.sqrt(magic);
    dlat = (dlat * 180.0) / ((a * (1 - ee)) / (magic * sqrtmagic) * pi);
    dlng = (dlng * 180.0) / (a / sqrtmagic * Math.cos(radlat) * pi);
    const mglat = lat + dlat;
    const mglng = lng + dlng;

    const data = {
        lng: parseFloat((lng * 2 - mglng).toFixed(6)),
        lat: parseFloat((lat * 2 - mglat).toFixed(6))
    }
    return data;
}

// WGS84 to GCJ-02
export const wgs84togcj02 = (lng, lat) => {
    const a = 6378245.0;
    const ee = 0.00669342162296594323;
    const pi = Math.PI;

    function transformlat (lng, lat) {
        let ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(
            lng));
        ret += (20.0 * Math.sin(6.0 * lng * pi) + 20.0 * Math.sin(2.0 * lng * pi)) * 2.0 / 3.0;
        ret += (20.0 * Math.sin(lat * pi) + 40.0 * Math.sin(lat / 3.0 * pi)) * 2.0 / 3.0;
        ret += (160.0 * Math.sin(lat / 12.0 * pi) + 320 * Math.sin(lat * pi / 30.0)) * 2.0 / 3.0;
        return ret;
    }

    function transformlng (lng, lat) {
        let ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng));
        ret += (20.0 * Math.sin(6.0 * lng * pi) + 20.0 * Math.sin(2.0 * lng * pi)) * 2.0 / 3.0;
        ret += (20.0 * Math.sin(lng * pi) + 40.0 * Math.sin(lng / 3.0 * pi)) * 2.0 / 3.0;
        ret += (150.0 * Math.sin(lng / 12.0 * pi) + 300.0 * Math.sin(lng / 30.0 * pi)) * 2.0 / 3.0;
        return ret;
    }

    let dlat = transformlat(lng - 105.0, lat - 35.0);
    let dlng = transformlng(lng - 105.0, lat - 35.0);
    const radlat = lat / 180.0 * pi;
    let magic = Math.sin(radlat);
    magic = 1 - ee * magic * magic;
    const sqrtmagic = Math.sqrt(magic);
    dlat = (dlat * 180.0) / ((a * (1 - ee)) / (magic * sqrtmagic) * pi);
    dlng = (dlng * 180.0) / (a / sqrtmagic * Math.cos(radlat) * pi);
    const mglat = lat + dlat;
    const mglng = lng + dlng;

    const data = {
        lng: parseFloat(mglng.toFixed(6)),
        lat: parseFloat(mglat.toFixed(6))
    }
    return data;
}